<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between">
        <h3>
          {{ locale('locale_key.data_owner_portal.main_navbar.inbox') }}
          <span *ngIf="isExpanded">{{ selectedTask.title }}</span>
        </h3>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button
            id="button-alignment"
            dropdownToggle
            type="button"
            class="btn page-toolbar-dropdown-toggle ms-4 mt-0 pt-0"
            aria-controls="dropdown-alignment"
          >
            <i class="la la-ellipsis-h fs-2 mt-0 pt-0" style="rotate: 90deg"></i>
          </button>
          <ul
            id="dropdown-alignment"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
            role="menu"
            aria-labelledby="button-alignment"
          >
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button
                      type="button"
                      class="btn"
                      (click)="handleToolbarAction(toolbarButton.actionName)"
                      *ngIf="toolbarButton.visible()"
                    >
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-5" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <!-- content column-->
      <div
        class="flex-fill scroll-y"
        style="
          max-height: var(--page-content-minus-1-page-header-footer);
          height: var(--page-content-minus-1-page-header-footer);
        "
      >
        <div class="d-flex h-100 flex-row me-1">
          <div class="tab-content h-100 w-100 h-100 pt-1">
            <div class="mb-1 pb-0 h-100" [ngClass]="{ card: !isExpanded }">
              <div class="card-body px-0 h-100 pt-0 mb-0 pb-0">
                <div class="row mt-3 mx-3" *ngIf="!isExpanded">
                  <div class="col-md-6 col-lg-3 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{ locale('locale_key.general.search') }}"
                      #search
                      (input)="applySearchFilter(search.value)"
                    />
                  </div>
                  <div class="col-lg-9 mb-3 btn-group" dropdown container="body" [insideClick]="true">
                    <button
                      type="button"
                      class="form-control filter-button"
                      id="filter-button"
                      dropdownToggle
                      aria-controls="filter-dropdown-menu"
                      style="text-align: left"
                    >
                      <p class="d-inline m-0">
                        <i class="la la-filter"></i>
                        {{ locale('locale_key.pages.datahub.filter_dropdown') }}
                      </p>
                      <p
                        class="text-muted filter-text text-truncate col-7 m-0"
                        [title]="getCustomFilterText()"
                        *ngIf="customMultipleFilters.length > 0 || selectedStatus"
                      >
                        :{{ getCustomFilterText() }}
                      </p>
                    </button>
                    <div
                      class="ms-3 dropdown-menu"
                      id="filter-dropdown-menu"
                      *dropdownMenu
                      role="menu"
                      aria-labelledby="filter-button"
                      [ngClass]="{ 'width-dropdown': screenSize.isXSmallSize() }"
                    >
                      <div class="accordion-body px-0 pt-0">
                        <div class="bg-light-gray col pt-3">
                          <div class="d-flex flex-row justify-content-between px-2">
                            <span class="bold">{{ locale('locale_key.pages.datahub.applied_filters') }}</span>
                            <a class="underline" (click)="clearAllFilters()">
                              {{ locale('locale_key.pages.datahub.button.clear_all_filters') }}
                            </a>
                          </div>
                          <div class="row px-2">
                            <i class="text-muted" *ngIf="customMultipleFilters.length == 0 && !selectedStatus">
                              {{ locale('locale_key.pages.datahub.filter_none') }}
                            </i>
                            <p
                              class="text-muted text-truncate"
                              *ngIf="customMultipleFilters.length > 0 || selectedStatus"
                            >
                              {{ getCustomFilterText() }}
                            </p>
                          </div>
                        </div>
                        <div class="d-flex flex-row justify-content-between px-2 py-3 thin-border-t">
                          <div class="d-flex" [ngClass]="screenSize.isXSmallSize() ? 'flex-column' : 'flex-row'">
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                [(ngModel)]="selectedStatus"
                                (change)="applyFilters()"
                                name="status"
                                value="CREATED"
                                id="sent"
                              />
                              <label class="form-check-label" for="sent">
                                {{ locale('locale_key.pages.datahub.do_submissions.filter.show_requests_sent') }}
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                [(ngModel)]="selectedStatus"
                                (change)="applyFilters()"
                                name="status"
                                value="APPROVED"
                                id="completed"
                              />
                              <label class="form-check-label" for="completed">
                                {{ locale('locale_key.pages.datahub.do_submissions.filter.show_completed_requests') }}
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                [(ngModel)]="selectedStatus"
                                (change)="applyFilters()"
                                name="status"
                                value="IN_PROGRESS"
                                id="inProgress"
                              />
                              <label class="form-check-label" for="inProgress">
                                {{ locale('locale_key.pages.datahub.do_submissions.filter.show_in_progress_requests') }}
                              </label>
                            </div>
                          </div>
                          <div class="d-flex flex-row">
                            <a class="underline" (click)="selectedStatus = null; applyFilters()">
                              {{ locale('locale_key.pages.datahub.button.clear_filter') }}
                            </a>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="accordion" id="accordionExample1">
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  <span class="cursor" data-bs-toggle="tooltip" data-placement="top">
                                    {{ locale('locale_key.general.user_role.sustainability_managers') }}
                                  </span>
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body scroll" style="max-height: 20rem">
                                  <div class="row">
                                    <div class="col-md-6 col-lg-6 mb-3">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="{{ locale('locale_key.general.search') }}"
                                        (input)="filterSMs(contactSearch.value)"
                                        #contactSearch
                                      />
                                    </div>
                                    <div class="col-md-6 col-lg-6 mb-3 text-end">
                                      <a class="underline" (click)="selectAll('contactCheckbox')">
                                        {{ locale('locale_key.general.action.select_all') }}
                                      </a>
                                      <a class="underline" (click)="deselectAll('contactCheckbox')">
                                        {{ locale('locale_key.general.action.deselect_all') }}
                                      </a>
                                    </div>
                                  </div>
                                  <div *ngFor="let contact of filteredSMs; let i = index">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      class="contactCheckbox"
                                      id="company{{ i }}"
                                      [checked]="isCustomFilterSelected(contact)"
                                      (change)="changeCustomFilters($event, contact, true)"
                                    />
                                    <label class="col-4">{{ contact.name }}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  <span class="cursor" data-bs-toggle="tooltip" data-placement="top">
                                    {{ locale('locale_key.pages.datahub.do_submissions.button.companies') }}
                                  </span>
                                </button>
                              </h2>
                              <div
                                id="collapseThree"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body scroll" style="max-height: 20rem">
                                  <div class="row">
                                    <div class="col-md-6 col-lg-6 mb-3">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="{{ locale('locale_key.general.search') }}"
                                        (input)="filterCompanies(companySearch.value)"
                                        #companySearch
                                      />
                                    </div>
                                    <div class="col-md-6 col-lg-6 mb-3 text-end">
                                      <a class="underline" (click)="selectAll('companyCheckbox')">
                                        {{ locale('locale_key.general.action.select_all') }}
                                      </a>
                                      <a class="underline" (click)="deselectAll('companyCheckbox')">
                                        {{ locale('locale_key.general.action.deselect_all') }}
                                      </a>
                                    </div>
                                  </div>
                                  <div *ngFor="let company of filteredCompanies; let i = index">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      class="companyCheckbox"
                                      id="company{{ i }}"
                                      [checked]="isCustomFilterSelected(company)"
                                      (change)="changeCustomFilters($event, company)"
                                    />
                                    <label>{{ company.name }}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-lg-row flex-column scroll-y bg-light-main"
                  [ngStyle]="!isExpanded ? { height: '82vh' } : { height: '' }"
                >
                  <div class="col-lg-5 col-md-12 ps-3 pe-4 mb-0 pb-0" *ngIf="!isExpanded">
                    <div class="d-flex flex-row justify-content-between py-3">
                      <div class="w-50 text-start custom-div">
                        <button
                          class="btn dropdown-toggle form-control left-align-dropdown-button dark-text ms-2"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style="max-height: 33.5px !important"
                        >
                          <span *ngIf="isToDoListOpen" style="display: inline-block; width: 90%">
                            <i class="la la-list-alt fs-4 me-auto"></i>
                            <span class="me-auto">{{ locale('locale_key.pages.task_portal.option.to_do') }}</span>
                          </span>
                          <span *ngIf="!isToDoListOpen" style="display: inline-block; width: 90%">
                            <i
                              class="la la-check-circle-o grad-green fs-4 me-auto"
                              style="position: relative; top: 2px"
                            ></i>
                            <span class="me-auto">{{ locale('locale_key.pages.task_portal.option.done_list') }}</span>
                          </span>
                        </button>
                        <ul class="dropdown-menu ms-0 custom-dropdown">
                          <a
                            class="dropdown-item d-flex align-items-center ps-2"
                            *ngIf="isToDoListOpen"
                            (click)="selectedTask = null; isToDoListOpen = false"
                          >
                            <i
                              class="la la-check-circle-o grad-green fs-4 me-2"
                              style="position: relative; top: 2px"
                            ></i>
                            {{ locale('locale_key.pages.task_portal.option.done_list') }}
                          </a>
                          <a
                            class="dropdown-item d-flex align-items-center ps-2"
                            *ngIf="!isToDoListOpen"
                            (click)="selectedTask = null; isToDoListOpen = true"
                          >
                            <i class="la la-list-alt fs-4 me-2"></i>
                            {{ locale('locale_key.pages.task_portal.option.to_do') }}
                          </a>
                        </ul>
                      </div>
                      <div class="d-flex flex-row ms-auto" *ngIf="isToDoListOpen">
                        <div
                          class="icon-circle-md bg-selected border my-auto"
                          style="border: 1px solid #19bfd3 !important"
                        >
                          <span class="bold fs-5 dark-text">
                            {{ getToDoActivitiesGroup().length > 100 ? '99+' : getToDoActivitiesGroup().length }}
                          </span>
                        </div>
                        <span class="my-auto ps-2 dark-text">
                          {{ locale('locale_key.general.quick_tips.tasks.title') }}
                        </span>
                      </div>
                    </div>
                    <div class="d-flex flex-column mx-auto mb-3 pb-3 scroll-y h-100">
                      <ng-container *ngIf="isToDoListOpen; else doneList">
                        <ng-container *ngFor="let toDoActivitiesGroup of getToDoActivitiesGroup(); let i = index">
                          <inbox-card
                            (click)="selectNewActivity(i)"
                            [isSelected]="selectedActivity == toDoActivitiesGroup.activities[0]"
                            [requestGroup]="getRequestGroup(toDoActivitiesGroup.activities[0].requestGroupId)"
                            [activity]="toDoActivitiesGroup.activities[0]"
                          ></inbox-card>
                        </ng-container>
                      </ng-container>
                      <ng-template #doneList>
                        <ng-container *ngFor="let task of getDoneRequestList(); let i = index">
                          <inbox-card
                            (click)="selectDoneRequest(task)"
                            [isSelected]="selectedActivity == getLastActivity(task)"
                            [requestGroup]="getRequestGroup(task.requestGroupId)"
                            [activity]="getLastActivity(task)"
                          ></inbox-card>
                        </ng-container>
                      </ng-template>
                    </div>
                  </div>
                  <div
                    class="col-md-12"
                    [ngClass]="isExpanded ? 'col-lg-12 mb-0 pb-0 h-100' : 'col-lg-7 h-100 '"
                    *ngIf="selectedTask"
                  >
                    <answer
                      [task]="selectedTask"
                      [isFromInboxPage]="true"
                      [showQuestionnaireIcon]="showQuestionnaireIcon && !isExpanded"
                      (answerModeStarted)="answerModeStarted()"
                      (endAnswerMode)="endAnswerMode()"
                      (openQuestionnaire)="openQuestionnaire()"
                    ></answer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Subject } from 'rxjs'
import { TableDataFe } from 'src/app/model/schema/TableDataFe'
import { DataGridRow } from '../../projects/data-grid-ui/model/DataGridRow'
import { DataGridTableData } from '../../projects/data-grid-ui/model/DataGridTableData'
import { DataGridTableMode } from '../../projects/data-grid-ui/model/DataGridTableMode'
import { DataGridObserverInterface } from '../../projects/data-grid-ui/service/DataGridObserverInterface'
import { DataGridServiceInterface } from '../../projects/data-grid-ui/service/DataGridServiceInterface'
import { DataGridColumnSchema } from '../../projects/data-grid-ui/model/DataGridColumnSchema'
import { ColumnSchemaFe } from 'src/app/model/schema/ColumnSchemaFe'
import * as XLSX from 'xlsx'

export class RequestEntryDataGridIntegrationService implements DataGridServiceInterface {
  observer!: DataGridObserverInterface & { table?: DataGridTableData }

  table: TableDataFe
  selectedRows = new Subject<DataGridRow[]>()
  mode = DataGridTableMode.GET_SELECTED_TABLE_ROWS

  constructor(table: TableDataFe) {
    this.table = table
  }

  getMode(): string {
    return this.mode
  }

  registerObserver(observer: DataGridObserverInterface): void {
    this.observer = observer
  }

  getMainTableData(): DataGridTableData {
    let tableData = this.table.toDataGridTableData()
    return tableData
  }

  getReferenceTables(): DataGridTableData[] {
    return []
  }

  hasMainTablePagination(): boolean {
    return false
  }

  async loadMainTablePage(pageSize: number, pageNumber: number): Promise<DataGridTableData> {
    let tableData = this.table.toDataGridTableData()
    return tableData
  }

  async exportSelectedRows(rows: DataGridRow[]): Promise<void> {
    this.selectedRows.next(rows)
  }

  markDataModified(modified: boolean, isDataValid: boolean): void {}
  hasDataModifiedElseWhere(): boolean {
    return false
  }
  saveReferenceTable(table: DataGridTableData): void {}

  getNoOfMinRows(): number {
    return 5
  }

  downloadSheet(label: string, exportType?: string) {
    const extension: string = exportType && ['csv', 'xlsx'].includes(exportType) ? exportType : 'csv'
    let fileName = `${label}_Table.${extension}`
    const data = this.table.extractContentToBeExported('Unit')

    let blob
    if (extension === 'xlsx') {
      let workbook = XLSX.utils.book_new()
      let worksheet = XLSX.utils.aoa_to_sheet(data)
      XLSX.utils.book_append_sheet(workbook, worksheet)
      let excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    } else if (extension === 'csv') {
      const csv = data.map((row) => row.join(',')).join('\n')
      blob = new Blob([csv], { type: 'text/csv' })
    }

    var link = document.createElement('a')
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  updateColumnSchema(data: DataGridColumnSchema[]) {
    for (const index in data) {
      this.table.dataSchema[index] = ColumnSchemaFe.fromDataGridColumnSchema(this.observer.table.dataSchema[index])
    }
  }
}

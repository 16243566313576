export class ColumnTypeFe {
  public static BOOLEAN = 'BOOLEAN'
  public static INTEGER = 'INTEGER'
  public static RECORD = 'RECORD'
  public static STRING = 'STRING'
  public static EMISSION_FACTOR = 'EMISSION_FACTOR'
  public static FLOAT64 = 'FLOAT64'
  public static NUMERIC = 'NUMERIC'
  public static FLOAT = 'FLOAT'
}

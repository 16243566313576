import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { KpiDefFe } from '../kpi/KpiDefFe'
import { RowFe } from '../schema/RowFe'
import { TableDataFe } from '../schema/TableDataFe'
import { EntityFe } from './EntityFe'
import * as XLSX from 'xlsx'
import { ColumnSchemaFe } from '../schema/ColumnSchemaFe'
import { StageTableDataGridIntergationService } from 'src/app/components/data/StageTableDataGridIntergationService'
import { SourceFe } from '../schema/SourceFe'
import { RequestGroupFe } from '../data-suppliers/request/RequestGroupFe'
import { RequestFe } from '../data-suppliers/request/RequestFe'
import { DirectEntryInfoFe, OLD_STAGE_TABLE_KEYWORD, STAGE_TABLE_KEYWORD } from '../directEntry/DirectEntryInfoFe'
import { Subject, from } from 'rxjs'
import { DataGridRow } from 'src/app/components/projects/data-grid-ui/model/DataGridRow'
import { DataGridTableData } from 'src/app/components/projects/data-grid-ui/model/DataGridTableData'
import { DataGridTableMode } from 'src/app/components/projects/data-grid-ui/model/DataGridTableMode'
import { DataGridObserverInterface } from 'src/app/components/projects/data-grid-ui/service/DataGridObserverInterface'
import { DataGridServiceInterface } from 'src/app/components/projects/data-grid-ui/service/DataGridServiceInterface'
import { DataGridColumnSchema } from 'src/app/components/projects/data-grid-ui/model/DataGridColumnSchema'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { DataGridColumnType } from 'src/app/components/projects/data-grid-ui/model/DataGridColumnType'
import { AbstractEmissionFactorFe } from '../emissions/AbstractEmissionFactorFe'
import { DataGridStatus } from 'src/app/components/projects/data-grid-ui/model/DataGridStatus'
import { EmissionFactorConversionFe } from '../emissions/EmissionFactorConversionFe'
export class MasterTableDataGridIntergationService implements DataGridServiceInterface {
  stateService: StateServiceFe
  entity: EntityFe
  currentPageData: TableDataFe
  fullTableData: TableDataFe
  backendTableData: any
  observer: DataGridObserverInterface | undefined
  mode: string = DataGridTableMode.VIEW
  hasTablePagination = true
  private selectedRowId: string
  selectedRow: RowFe
  selectedColumnNo: number
  traceSource = new Subject<RowFe>()
  downloading = false
  allRequests: RequestFe[] = []
  allDirectEntyInfos: DirectEntryInfoFe[] = []
  openEfModal = new Subject()
  showEfDetailsPanel = new Subject<AbstractEmissionFactorFe>()

  constructor(
    entity: EntityFe,
    stateService: StateServiceFe,
    public languageService: LanguageService
  ) {
    this.entity = entity
    this.stateService = stateService
    this.stateService.getAllRequests().then((allRequests) => (this.allRequests = allRequests))
    this.stateService.getStageTableInfos().then((stageTableInfos) => (this.allDirectEntyInfos = stageTableInfos))
    stateService.depTaxonomyInfoSubject.subscribe(async (updated) => {
      let { depTaxonomy } = await stateService.getTaxonomyInfos()
      let updatedEntity = depTaxonomy.entityByKey(this.entity.key)
      if (updatedEntity) {
        this.entity = updatedEntity
      }
    })
  }

  registerObserver(observer: DataGridObserverInterface): void {
    this.observer = observer
  }

  getMode(): string {
    return this.mode
  }

  getMainTableData(): DataGridTableData {
    if (this.backendTableData) {
      this.currentPageData = TableDataFe.fromTransfer(
        this.backendTableData,
        this.entity,
        this.languageService.getDisplayActiveLanguage(),
        false
      )
      this.currentPageData.key = this.entity.key
    }
    if (this.observer && this.selectedRowId) {
      this.observer.setSelectedRow(this.selectedRowId)
    }
    let dgtd = this.currentPageData.toDataGridTableData()
    return dgtd
  }

  hasMainTablePagination(): boolean {
    return this.hasTablePagination
  }

  setSelectedRowId(rowId: string): void {
    this.selectedRowId = rowId
    if (this.currentPageData) {
      let doesLoadedHasRow = this.currentPageData.rows.find((row) => row.rowId == this.selectedRowId)
      if (!doesLoadedHasRow) {
        this.resetTable()
        this.loadMainTablePage()
      }
    }
  }

  async loadMainTablePage(pageSize: number = 10, pageNumber: number = 1): Promise<DataGridTableData> {
    this.backendTableData = await this.stateService.readMasterTable(
      this.entity.key,
      pageSize,
      pageNumber,
      this.selectedRowId ? [this.selectedRowId] : null
    )
    this.currentPageData = TableDataFe.fromTransfer(
      this.backendTableData,
      this.entity,
      this.languageService.getDisplayActiveLanguage(),
      false
    )
    this.currentPageData.key = this.entity.key
    let dgtd = this.currentPageData.toDataGridTableData()
    return dgtd
  }

  markDataModified(modified: boolean, isDataValid: boolean): void {}

  hasDataModifiedElseWhere(): boolean {
    return false
  }

  saveReferenceTable(table: DataGridTableData): void {}

  async exportSelectedRows(rows: DataGridRow[]): Promise<void> {
    this.selectedRow = this.currentPageData.rows.find((row) => rows[0].rowId == row.rowId)
    this.traceSource.next(this.selectedRow)
  }

  getReferenceTables(): DataGridTableData[] {
    return []
  }

  getNoOfMinRows(): number {
    return 5
  }

  async downloadCurrentPage(exportType?: string): Promise<void> {
    this.downloading = true
    const extension: string = exportType && ['csv', 'xlsx'].includes(exportType) ? exportType : 'csv'
    let fileName = `${this.entity.label}.${extension}`
    if (this.backendTableData) {
      let table = TableDataFe.fromTransfer(
        this.backendTableData,
        this.entity,
        this.languageService.getDisplayActiveLanguage(),
        false
      )
      await this.saveTable(table, fileName)
    } else {
      await this.saveTable(this.currentPageData, fileName)
    }
    this.downloading = false
  }

  async saveTable(table: TableDataFe, fileName: string): Promise<void> {
    const data = table.extractContentToBeExported(this.languageService.getLocale('locale_key.pages.data_category.unit'))

    const sheet = XLSX.utils.aoa_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, sheet)
    await XLSX.writeFile(workbook, fileName)
  }

  async downloadWholeTable(exportType?: string): Promise<void> {
    this.downloading = true
    let backendTableData = this.fullTableData
    if (!this.fullTableData) {
      backendTableData = await this.stateService.readWholeMasterTable(this.entity.key)
      this.fullTableData = backendTableData
    }
    // set default csv format as previous logic, unless the method caller requests xlsx format
    const extension: string = exportType && ['csv', 'xlsx'].includes(exportType) ? exportType : 'csv'
    let fileName = `${this.entity.getLabel()}.${extension}`
    let table = TableDataFe.fromTransfer(
      backendTableData,
      this.entity,
      this.languageService.getDisplayActiveLanguage(),
      false
    )
    await this.saveTable(table, fileName)
    this.downloading = false
  }

  getSourceColumns(): DataGridColumnSchema[] {
    let typeCol = new DataGridColumnSchema()
    typeCol.name = 'TYPE'
    typeCol.label = this.languageService.getLocale('locale_key.general.type')
    let reporterCol = new DataGridColumnSchema()
    reporterCol.name = 'REPORTER'
    reporterCol.label = this.languageService.getLocale('locale_key.general.reporter')
    let companyCol = new DataGridColumnSchema()
    companyCol.name = 'COMPANY'
    companyCol.label = this.languageService.getLocale('locale_key.general.user_information.company')
    let columns = [typeCol, reporterCol, companyCol]
    return columns
  }

  getSourceRowValue(x: DataGridRow): string[] {
    let row = this.currentPageData.rows.find((r) => r.rowId == x.rowId)
    let source = row.mgmtRowValues[1]
    let sourceId = row.mgmtRowValues[2]
    let values = [source]
    if (source == SourceFe.DATA_REQUEST) {
      let rid = SourceFe.getRequestId(sourceId)
      let request = this.allRequests.find((r) => r.id === rid)
      values.push(request?.getAssigneeName(), request?.supplierCompanyName)
    } else if (source == SourceFe.DIRECT_ENTRY) {
      let info = this.allDirectEntyInfos.find(
        (info) => info.dbTableName() == sourceId.replaceAll(OLD_STAGE_TABLE_KEYWORD, STAGE_TABLE_KEYWORD)
      )
      values.push(`${info?.creatorFirstName} ${info?.creatorLastName}`, this.stateService.activeWorkspace.companyName)
    } else {
      values.push(`System`, `SustainLab`)
    }
    return values
  }

  resetTable() {
    this.currentPageData = null
    this.backendTableData = null
    this.fullTableData = null
  }

  getEntityCols() {
    return this.entity.columns
  }

  overWriteEf(dgrow: DataGridRow, colNo: number) {
    this.selectedRow = this.currentPageData.rows.find((row) => dgrow.rowId == row.rowId)
    this.selectedColumnNo = colNo - this.currentPageData.managementSchema.length
    this.openEfModal.next(true)
  }

  showEfDetails(dgrow: DataGridRow, colNo: number) {
    this.selectedRow = this.currentPageData.rows.find((row) => dgrow.rowId == row.rowId)
    this.selectedColumnNo = colNo - this.currentPageData.managementSchema.length
    let ef = EmissionFactorConversionFe.fromTransfer(this.selectedRow.values[this.selectedColumnNo])
    this.showEfDetailsPanel.next(ef)
  }

  async setEmissionFactor(ef: AbstractEmissionFactorFe) {
    let rowNo = 0
    this.backendTableData.rows.forEach((r, index) => {
      if (r.rowId == this.selectedRow.rowId) {
        rowNo = index
        r.values[this.selectedColumnNo] = ef
        r.status = DataGridStatus.MODIFIED
      }
    })
    let updatedTableData = TableDataFe.fromTransfer(
      this.backendTableData,
      this.entity,
      this.languageService.getDisplayActiveLanguage(),
      false
    )
    updatedTableData = updatedTableData.toTransfer()
    await this.stateService.updateMasterTable(updatedTableData)
    this.backendTableData.rows.forEach((r) => {
      if (r.rowId == this.selectedRow.rowId) {
        r.status = DataGridStatus.STORED
      }
    })
    this.observer.setEmissionFactor(this.selectedColumnNo, rowNo, this.selectedRow.rowId, ef)
    this.observer.renderNewDataTable()
  }
}
